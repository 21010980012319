<template>
  <div v-loading="loading">
    <div class="send-mail-box">
      <h2 class="fw-normal fs-3 mb-0 text-dark">Review &amp; Send</h2>
      <p class="fw-normal fs-7">Message to Recipient</p>
      <div id="sendDocumentDialog">
        <el-form ref="form">
          <el-row justify="center" :gutter="30">
            <el-col :lg="10" :sm="24">
              <el-form-item label="To Recipients">
                <el-select
                  v-model="mailSendTo"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  class="w-100 border-0"
                  placeholder="Add Recipients"
                  disabled
                >
                  <el-option
                    v-for="(contact, index) in contactRecipients"
                    :key="index"
                    :label="contact.email"
                    :value="contact.email"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="CC Recipients">
                <el-select
                  v-model="mailCCSendTo"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  class="w-100 border-0"
                  placeholder="Add CC Recipients"
                  disabled
                >
                  <el-option
                    v-for="(contact, index) in contactRecipients"
                    :key="index"
                    :label="contact.email"
                    :value="contact.email"
                    :disabled="
                      fetchSignUsers.indexOf(contact.email) != -1 &&
                      contact.email == 'CC'
                    "
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :lg="14" :sm="24">
              <el-form-item label="Email Subject">
                <el-input
                  type="textarea"
                  v-model="mailSubject"
                  placeholder="Subject"
                  :autosize="{ minRows: 2 }"
                  maxlength="70"
                  show-word-limit
                >
                </el-input>
                <!-- <div class="text-muted fs-9 opacity-80">Characters remaining : 70</div> -->
              </el-form-item>
              <el-form-item label="Message">
                <el-input
                  type="textarea"
                  v-model="mailContent"
                  placeholder="Type your message here"
                  class="border-0"
                  rows="8"
                  maxlength="10000"
                  show-word-limit
                ></el-input>

                <!-- <div class="text-muted fs-9 opacity-80">Characters remaining : 10000</div> -->
              </el-form-item>
              <div class="card-footer mt-1 text-right">
                <el-button @click="goBack" class="fs-7">Back</el-button>
                <el-button
                  type="danger"
                  class="fs-7"
                  @click="checkIsShowSettignsVisible"
                  :loading="loading"
                  >Send Document</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <el-dialog
      :visible.sync="sentDocumentSuccess"
      width="100%"
      class="dialog-success outersize-confirm-type-three"
      title=" Document Sent"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span>
        <img
          class="imgstyle"
          alt="Document Sent"
          src="@/assets/img/sentDocumentSuccess.gif"
        />
        <p class="sent-text">Document has been sent</p>
      </span>

      <p class="pstyle" style="width: 100%">
        we will send you an email notification when any action is taken on the
        document:
      </p>
      <p
        class="subpstyle"
        style="text-align: center; width: 100%; margin-left: 0px"
      >
        if someone view,signs or declined etc...
      </p>

      <div style="-ms-transform: translateX(-30%); transform: translateX(31%)">
        <el-button @click="saveDocumentAsTemplate" style="margin-bottom: 5px"
          >Save as Template</el-button
        >
        <el-button
          id="doc"
          type="primary"
          @click="gotoViewDocument"
          style="margin-bottom: 5px"
          >View Document</el-button
        >
      </div>
    </el-dialog>
    <ConfigureSettings
      v-if="docSettingsVisible"
      :configurableDocumentId="configurableDocumentId"
      :settingsData="getDefaultSettings"
      :docSettingsVisible="docSettingsVisible"
      @close="docSettingsVisible = false"
      v-on:updated="sendDocument"
    ></ConfigureSettings>
  </div>
</template>

<script>
const ConfigureSettings = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureSettingsNewTemp"
  );
import { mapGetters } from "vuex";
export default {
  name: "companyDocuments-send-Document-OnMail",
  components: { ConfigureSettings },
  data() {
    return {
      contactRecipients: [],
      mailSendTo: [],
      mailCCSendTo: [],
      mailContent: "",
      mailSubject: "",
      employeeDocumentId: "",
      loading: false,

      // configure document details
      backgroundImages: [],
      documentName: "",
      pages: [],
      storedFormBuilderWidth: null,
      templateTitle: "",
      documentUsers: [],
      elements: [],
      enforceSignatureOrder: true,
      configurableDocumentId: "",
      is_cc: false,
      docSettingsVisible: false,
      sentDocumentSuccess: false,
      showSettings: false,
      sender_auto_fill: false,
      isAnyoneCanApprove: false,
    };
  },
  async mounted() {
    await this.loadData();
    this.$store.dispatch("settings/fetchDefaultSettings");
    await this.getUserApplicationSettignsVisible();
    await this.getDocumentExpirationSettings(this.$route.params.id);
  },
  computed: {
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("settings", ["getDefaultSettings", "getApplicationSettings"]),
    ...mapGetters("documents", [
      "getDraftDocumentCreateData",
      "getDocumentSendStatus",
      "getSingleDocumentData",
      "getDocumnetSentDocumnet",
      "getDocumentSaveAsTemplate",
      "getDocumentSettings",
    ]),
    fetchSignUsers() {
      return this.documentUsers.flatMap((element) => element.email);
    },
  },
  methods: {
    async getDocumentExpirationSettings(id) {
      try {
        let params = {
          document_id: id,
        };
        await this.$store.dispatch("documents/fetchDocumentSettings", params);
        this.loading = false;
      } catch {
        this.$notify.error({
          title: "Error",
          message: "Document",
        });
      }
    },
    async getUserApplicationSettignsVisible() {
      try {
        await this.$store.dispatch("settings/fetchApplicationSettings");
        if (this.getApplicationSettings && this.getApplicationSettings.data) {
          if (this.getApplicationSettings.data.document_signature) {
            this.showSettings =
              this.getApplicationSettings.data.document_signature.show_document_settings_when_sent;
            this.sender_auto_fill =
              this.getApplicationSettings.data.document_signature.allow_sender_auto_fill;
          }
        }
      } catch {
        this.$notify.error({
          title: "Error",
          message: "Error Document",
        });
      }
    },
    checkIsShowSettignsVisible() {
      if (this.showSettings) {
        this.docSettingsVisible = true;
      } else {
        this.sendDocument();
      }
    },
    handleCloseDialog() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    gotoViewDocument() {
      // let draftId = this.getDraftDocumentCreateData.data._id;
      this.$router.push({
        name: "employee-documents-custom-document",
        params: {
          employee_document_id: this.getDocumnetSentDocumnet._id,
        },
      });
    },
    async saveDocumentAsTemplate() {
      if (this.getDocumnetSentDocumnet.configurable_document_id) {
        let params = {
          configurable_document_id:
            this.getDocumnetSentDocumnet.configurable_document_id,
          company_document_id: this.getDocumnetSentDocumnet.company_document_id,
          employee_document_id: this.getDocumnetSentDocumnet._id,
        };
        await this.$store.dispatch("documents/saveAsTemplate", params);
        if (this.getDocumentSaveAsTemplate) {
          this.$notify({
            title: "Success",
            message: "Template updated successfully",
            type: "success",
          });
          this.$router.push({
            name: "Dashboard",
          });
        }
      }
    },
    gotoViewDocumnet() {
      this.$router.push({
        name: "employee-documents-custom-document",
        params: {
          employee_document_id: this.getDocumnetSentDocumnet._id,
        },
      });
    },
    async loadData() {
      try {
        this.loading = true;
        this.employeeDocumentId = this.$route.params.id;
        await this.fetchDocumentDetails();

        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,
          type: "CONTACT",
        });
        this.contactRecipients =
          this.getAllContacts && this.getAllContacts.data
            ? [...this.getAllContacts.data]
            : [];
      } catch (err) {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async fetchDocumentDetails() {
      await this.$store.dispatch("documents/fetchDocumentDataById", {
        document_id: this.employeeDocumentId,
      });
      this.documentName = this.getSingleDocumentData.data.title;
      this.configurableDocumentId =
        this.getSingleDocumentData.data.configurable_document_id;
      this.documentUsers = this.getSingleDocumentData.data.document_users;
      this.isAnyoneCanApprove =
        this.getSingleDocumentData.data.is_anyone_can_approve;
      this.enforceSignatureOrder =
        this.getSingleDocumentData.data.enforce_signature_order;
      this.documentUsers.map((user) => {
        if (user.user_type == "CC") {
          this.mailCCSendTo.push(user.email);
        } else {
          this.mailSendTo.push(user.email);
        }
        return user;
      });
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.email_data &&
        this.getSingleDocumentData.data.email_data.data &&
        this.getSingleDocumentData.data.email_data.subject &&
        this.getSingleDocumentData.data.email_data.data.length &&
        this.getSingleDocumentData.data.email_data.subject.length
      ) {
        this.mailSubject = this.getSingleDocumentData.data.email_data.subject;
        this.mailContent = this.getSingleDocumentData.data.email_data.data;
      }
      this.elements =
        this.getSingleDocumentData.data.configurable_document_data.pages[0].fields;
    },
    addCCusers() {
      let ccContacts = this.getAllContacts.data.filter((user) => {
        return (
          this.mailCCSendTo.indexOf(user.email) != -1 &&
          this.fetchSignUsers.indexOf(user.email) == -1
        );
      });
      ccContacts.map((user) => {
        user.fields_required = false;
        user.has_approval_access = false;
        user.type = "RECEIVER";
        user.user_type = "CC";
        user.value = "CC";
        user.contact = user._id;
      });
      Array.prototype.push.apply(this.documentUsers, ccContacts);
      this.documentUsers.map((user, index) => {
        user.e_signature_order = index;
        return user;
      });
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    async sendDocument(settings) {
      try {
        this.loading = true;
        this.docSettingsVisible = false;
        let documentData = this.prepareDocumentData();
        let timeZone = this.getTimeZone();
        // let users = this.documentUsers.map((user, index) => {
        //   user.e_signature_order = index;
        //   return user;
        // });
        let emailData = this.prepareEmailData();
        this.addCCusers();
        let expiration_settings = this.getDocumentSettings.data;
        if (
          this.getDocumentSettings &&
          this.getDocumentSettings.data &&
          this.getDocumentSettings.data.expiration_settings &&
          !this.getDocumentSettings.data.expiration_settings
            .document_expired_date
        ) {
          let date = new Date();
          date.setDate(
            date.getDate() +
              this.getDocumentSettings.data.expiration_settings
                .expire_documents_in_days
          );
          expiration_settings.expiration_settings.document_expired_date = date;
        }

        this.documentUsers.find((el) => {
          if (el && el.user_type == "CC" && !el.sent_status) {
            el.sent_status = true;
          }
        });
        if (this.enforceSignatureOrder) {
          this.documentUsers.find((el) => {
            if (
              el &&
              el.type === "SENDER" &&
              el.e_signature_order &&
              (el.e_signature_order > 0 || el.e_signature_order != 0)
            ) {
              this.sender_auto_fill = false;
            }
          });
        }
        let params = {
          employee_document_id: this.$route.params.id,
          company_document_id: this.$route.params.id,
          document_users: this.documentUsers,
          document_data: documentData,
          sender_time_zone: timeZone,
          name: this.documentName,
          enforce_signature_order: this.enforceSignatureOrder || false,
          email_data: emailData,
          document_settings: settings || expiration_settings,
          senderFilledAllFields: this.sender_auto_fill,
          is_anyone_can_approve: this.isAnyoneCanApprove,
        };
        let res = await this.$store.dispatch("documents/sendDocument", params);
        this.loading = false;
        console.log(
          "this.getDocumentSendStatus",
          this.getDocumentSendStatus,
          res
        );
        if (this.getDocumentSendStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document sent successfully",
          });
          this.docSettingsVisible = false;
          this.sentDocumentSuccess = true;
          // this.$router.push({
          //   path: "/documents/all",
          // });
        } else {
          this.$notify.error({
            title: "Error",
            message: "Document sent successfully",
          });
        }
      } catch (err) {
        this.loading = false;
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.message
        ) {
          this.$notify.error({
            title: "Error",
            message: `${
              err.response.data.data.message
                ? err.response.data.data.message
                : "Error Document"
            }`,
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error Document",
          });
        }
      }
    },

    prepareEmailData() {
      let emailData = {};
      let users = [...this.contactRecipients];
      let usersEmail = users.map((user) => user.email);
      emailData["signers"] = this.mailSendTo.map((user) => {
        let data = {};
        let userIndex = usersEmail.indexOf(user);
        if (userIndex != -1) {
          data["email"] = user;
          data["user_id"] = users[userIndex]._id;
        } else {
          data["email"] = user;
          data["user_id"] = "";
        }

        return data;
      });

      emailData["to_cc"] = this.mailCCSendTo.map((user) => {
        let data = {};
        let userIndex = usersEmail.indexOf(user);
        if (userIndex != -1) {
          data["email"] = user;
          data["user_id"] = users[userIndex]._id;
        } else {
          data["email"] = user;
          data["user_id"] = "";
        }
        return data;
      });
      emailData["data"] = this.mailContent;
      emailData["subject"] = this.mailSubject;
      return emailData;
    },
    prepareDocumentData() {
      let data = {};
      this.elements.forEach((element) => {
        if (element.content) {
          data[element.key] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if (element.files && element.files.length) {
          data[element.key] = element.files;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
  },
  beforeDestroy() {
    this.$store.commit("settings/setDefaultSettings", null, { root: true });
    this.$store.commit("settings/setApplicationSettings", null, { root: true });
    this.$store.commit("documents/setDraftDocumentCreateData", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSendStatus", null, { root: true });
    this.$store.commit("documents/setSingleDocumentData", null, { root: true });
    this.$store.commit("documents/setDocumnetSentDocumnet", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSaveAsTemplate", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSettings", null, { root: true });
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss" scoped>
.send-mail-box {
  max-width: 1100px;
  margin: 0 auto;
  #sendDocumentDialog {
    padding: 0;
    .el-form-item {
      padding: 5px 0px;
      margin-bottom: 5px;
      .el-textarea__inner {
        min-height: 40px !important;
        padding-top: 10px;
      }
      .el-form-item__label {
        line-height: 2;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
    }
  }
}
.sent-text {
  color: #212529;
  font-size: 22px;
  margin-top: -30px;
  margin-left: 30%;
}
#bing {
  background-color: #f24b91;
}
#doc {
  background-color: #f754a2;
  border: none;
}
.pstyle {
  width: 530px;
  margin-top: 10px;
  margin-left: 16%;
}
.subpstyle {
  margin-top: -15px;
  margin-left: 30%;
}
.imgstyle {
  margin-top: -40px;
  margin-left: 25%;
}
.dialog-footer {
  margin-top: 40px;
  margin-left: 25%;
}
</style>
